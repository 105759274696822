import styled from "styled-components";

import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import { Facebook } from "@styled-icons/boxicons-logos/Facebook";

export const FooterWrapper = styled.footer`
  background: #191f24;
  padding: 192px 0 117px;
  background-image: url(../../img/footer-img.png);
  background-position: 140%;
  background-repeat: no-repeat;
  position: relative;
  @media only screen and (max-width: 1400px) {
    background-image: none;
    padding: 54px 0 78px;
  }
  @media only screen and (max-width: 800px) {
    padding: 42px 0 78px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1400px) {
      flex-direction: column;
      text-align: center;
    }
    .imgContainer {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1400px) {
        margin: 0 auto;
        padding-bottom: 57px;
      }
      @media only screen and (max-width: 800px) {
        padding-bottom: 47px;
      }
    }
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      padding-bottom: 15px;
      @media only screen and (max-width: 1400px) {
        margin: 0 auto;
        padding-top: 70px;
      }
      @media only screen and (max-width: 800px) {
        padding: 60px 0 16px;
      }
    }
    .footerMenu {
      li {
        margin-bottom: 8px;
        a {
          font-size: 20px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.5);

          &:hover {
            color: #ffffff;
          }
        }
      }
    }
    p {
      font-size: 20px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);
      max-width: 440px;
      padding-bottom: 10px;
      @media only screen and (max-width: 1400px) {
        margin: 0 auto;
        font-size: 16px;
        line-height: 19px;
      }
    }
    .social {
      display: flex;
      justify-content: space-around;
      @media only screen and (max-width: 1400px) {
        width: 400px;
        margin: 0 auto;
      }
      @media only screen and (max-width: 800px) {
        width: 230px;
        margin: 0 auto;
      }
      a {
        display: block;
        width: 104px;
        height: 104px;
        background: rgba(255, 255, 255, 0.07);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 800px) {
          width: 64px;
          height: 64px;
        }
        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
        svg {
          width: 42px;
          height: 42px;
          margin-right: 0;
          @media only screen and (max-width: 800px) {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
`;

export const FooterFourUp = styled.div`
  background: #1e2328;
  padding: 17px;
  @media only screen and (max-width: 800px) {
    padding: 32px 28px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 800px) {
      flex-direction: column;
      padding: 0;
    }
    p {
      font-size: 14px;
      line-height: 131%;
      letter-spacing: 0.05em;
      color: rgba(255, 255, 255, 0.25);
      @media only screen and (max-width: 1400px) {
        max-width: 268px;
      }
      @media only screen and (max-width: 800px) {
        padding-bottom: 39px;
      }
    }
    a {
      .img {
        background-image: url(../../img/logo-fourup.png);
        width: 170px;
        height: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
`;

export const GrayInstagram = styled(Instagram)`
  color: rgba(255, 255, 255, 0.5);
  height: 24px;
  margin-right: 12px;
`;

export const GrayFacebook = styled(Facebook)`
  color: rgba(255, 255, 255, 0.5);
  height: 24px;
  margin-right: 12px;
`;
