import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import useSiteMetadata from "../SiteMetadata";
import { withPrefix } from "gatsby";
import GlobalStyles from "../../styles/global";

  const TemplateWrapper = ({ children,bg }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <GlobalStyles />
      <Helmet>
        <html lang="pt-br" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon.png`}
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:title" content={title} />
        <meta property="og:image" content={`${withPrefix("/")}img/logo.svg`} />
      </Helmet>
      <Navbar bg={bg}/>
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
