import styled from "styled-components";

export const NavBar = styled.div`
  .painel {
    background: #566576;
    padding: 12px 0;
    position: relative;
    z-index: 1;
    .container {
      display: flex;
      justify-content: space-between;
        @media only screen and (max-width: 500px) {
          flex-direction: column;
          text-align: center;
        }
      form{
        width: 100%;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 285px 3fr 1fr;
        align-items: center;
        padding-top: 12px;
        @media only screen and (max-width: 800px) {
          grid-template-columns: 1fr;

        }
        .radioType{
          display: flex;
          @media only screen and (max-width: 800px) {
            justify-content: center;
          }
          label{
            color: rgba(255, 255, 255, 0.5);
          }
          .radio{
              margin-left: 15px;
            }
        }
        .inputBlock{
          display: flex;
          @media only screen and (max-width: 500px) {
            display: block;
          }
          input{
            margin-bottom: 0;
            &:first-child{
              margin-right: 10px;
              @media only screen and (max-width: 500px) {
                margin: 0 0 10px 0;
              } 
            }
          }
        }
      }
      p {
        color: rgba(255, 255, 255, 0.5);
        font-style: italic;
        font-size: 14px;
        line-height: 17px;
      }
      .painelContact {
        display: flex;
        @media only screen and (max-width: 500px) {
          justify-content: center;
          text-align: center;
        }
        a{
          display: flex;
          margin-left: 32px;
          @media only screen and (max-width: 800px) {
            margin-left: 0;
          }
          img{
            width: 18px;
            height: 18px;
            margin-right:8px; 
            @media only screen and (max-width: 800px) {
              display: none;
            }
          }
        }
        p {
          font-style: normal;
          color: #ffffff;
          &:nth-child(1) {
            @media only screen and (max-width: 500px) { 
              padding-left: 0;
              padding-top: 4px;
            }
          }
        }
        .number {
          @media only screen and (max-width: 800px) {
            display: none;
          }
        }
      }
    }
  }
  .menu {
    background-color: #29323c ;
    padding: 26px 0;
    position: relative;
    z-index: 3;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .navMenu {
        display: flex;
        a {
          font-size: 16px;
          line-height: 19px;
          color: rgba(255, 255, 255, 0.5);
          margin-left: 32px;
          cursor: pointer;
          &:hover {
            color: #ffffff;
          }
        }
        @media only screen and (max-width: 800px) {
          display: none;
        }
        .divisor {
          margin-left: 32px;
          width: 1px;
          height: 16px;
          background: rgba(255, 255, 255, 0.1);
        }
      }
      .respMenuBtn {
        display: none;
        font-size: 16px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.5);
        margin-left: 32px;
        cursor: pointer;
        &:hover {
          color: #ffffff;
        }
        @media only screen and (max-width: 800px) {
          display: block;
        }
      }
    }
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  .modal-main {
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
`;

export const MenuResponsive = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  position: absolute;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #566576;
  z-index: 3;
  overflow: hidden;
  padding: 100px;
  text-align: center;
  @media only screen and (max-width: 800px) {
    padding: 40px;
  }
  .headerMenuResp {
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 48px;
    svg {
      position: absolute;
      top: 10px;
      right: 0;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      &:hover {
        color: #ffffff;
      }
    }
  }
  .menuRespItem {
    padding-bottom: 30px;
    a {
      font-size: 24px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      &:hover {
        color: #ffffff;
      }
    }
  }
`;
