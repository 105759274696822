import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

/* -->container<-- */
.container{
  max-width: 1366px;
  margin: 0 auto;
  padding: 0  64px;
  @media only screen and (max-width:801px ) {
    padding: 0 40px;
  }
  @media only screen and (max-width:480px) {
    padding: 0 24px;
  }
}

/* --> Hidden <-- */
.hidden{
  display:none !important;
}

/* -->inputRadio<-- */
.radio{
  position: relative;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
  float: right;
  margin-left: 45px;
  @media only screen and (max-width: 800px) {
    margin-left: 15px;
  }
  .label{
    position: relative;
    display: block;
    float: left;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);;
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;
    background: white;
    &:after{
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: #05AD35;
      transform: scale(0);
      transition: all .2s ease;
      opacity: .08;
      pointer-events: none;
    }
  }
  &:hover{
    .label:after{
      transform: scale(3.6);
  }
  }
}
 
input[type="radio"]:checked + .label{
  border-color: black;
  &:after{
    transform: scale(1);
    transition: all .2s cubic-bezier(.35,.9,.4,.9);
    opacity: 1;
  }
}

.menuBgCOlor {
  background-color: transparent !important;
  }

/* -->InputText<-- */
.inputText{
  width: 100%;
  padding: 16px;
  font-size: 16px;
  line-height: 19px;
  color: #1C1E21;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
  margin-bottom: 16px;
  &::placeholder{
    color: #1C1E21;
  }
}

/* -->Title<-- */
.title{
  h2{
    font-weight: bold;
    font-size: 56px;
    line-height: 68px;
    color: #2F3137;
    @media only screen and (max-width: 1400px) {
      font-size: 32px;
      line-height: 39px;
    }
  }
  .border{
    background: #69CF67;
    width: 56px;
    height: 6px;
  }
}

/* -->Retangle<-- */
.retangle{
  width: 774px;
  height: 230px;
  left: -10px;
  top: 302px;
  background: #69CF67;
  box-sizing: border-box;
  border-radius: 24px;
}


/* -->containerPost<-- */
.containerPost{
  max-width: 864px;
  margin: 0 auto;
  @media only screen and (max-width:883px ) {
    padding: 0  80px;
  }
  @media only screen and (max-width:600px) {
    padding: 0 24px;
  }
}

/* -->btn<-- */
.btn {
  background: #05AD35;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 19px;
  padding: 16px;
  cursor: pointer;
  outline: none;
  width: 100%;
  border:none;
  &:hover{
    transition: ease-in-out 0.7;
    background: #109034;
  }
}

/* --> ImgBG <-- */
.imgBg {
  position: relative;
  max-width: 770px;
  height: 270px;
  @media only screen and (max-width: 1650px) {
    height: 230px;
  }
  Img {
    position: relative;
    box-shadow: 8px 0px 8px -8px rgba(0, 0, 0, 0.25);
    border-radius: 0 24px 24px 0;
  }
}

/* -->BlogItem<-- */
.blogItem {
  background: #ffffff;
  box-shadow: 0px 2px 250px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .itemImg{
    border-radius: 4px 4px 0 0;
    .gatsby-image-wrapper {
      height: 187px;
      width: 100%;
      img {
        border-radius: 4px 4px 0 0;
      }
    } 
  }
  .itemContainer {
    padding: 23px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 214px;
    @media only screen and (max-width: 480px) {
      padding: 16px;
    }
    .ItemTagDate {
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 100%;
        color: rgba(255, 255, 255, 0.7);
        background: ${(props) => props.background};
        padding: 8px;
        border-radius: 4px;
      }
      span {
        display: block;
        font-weight: normal;
        font-size: 14px;
        line-height: 100%;
        text-align: right;
        color: #d4d5d7;
        @media only screen and (max-width: 480px) {
         font-size: 12px; 
        }
      }
    }
    .itemTitle {
       padding-bottom: 51px;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 120%;
        letter-spacing: 0.05em; 
        color: #272729;
        @media only screen and (max-width: 480px) {
          font-size: 14px;
        }
      }
    }
    .itemTimeLink{
      display: flex;
      justify-content: space-between;
      align-items: center;
      p{
        font-weight: normal;
        font-size: 12px;
        line-height: 100%;
        color: #D4D5D7;
      }
      a{
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;
        color: #2F3137;
        cursor: pointer;
        outline: none;
        padding: 5px 15px;
        border: 1px solid #F2F2F7;
        border-radius: 4px;
        &:hover{
          color: #000;
        }
        @media only screen and (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
  }
}

/* -->Block1<-- */
.block1{
  padding: 61px 96px;
  border-radius: 8px;
  width: 736px;
  @media only screen and (max-width: 1400px) {
    width: 440px;
    padding: 34px 31px 54px;
  }
  @media only screen and (max-width: 800px) {
    border-radius: 0;
    width: 100%;
  }
  h2{
    font-weight: bold;
    font-size: 48px;
    line-height: 100%;
    @media only screen and (max-width: 1400px) {
      font-size: 24px;
      line-height: 100%;
    }
  }
  p{
    font-size: 20px;
    line-height: 24px;
    padding: 20px 0 58px;
    text-align: left;
    @media only screen and (max-width: 1400px) {
      font-size: 14px;
      line-height: 17px;
      padding: 12px 0 24px;
    }
  }
  a{
    background: #69CF67;
    border-radius: 8px;
    padding: 24px 46px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    @media only screen and (max-width: 1400px) {
      font-size: 14px;
      line-height: 17px;
      padding: 24px 26px;
      display: flex;
      justify-content: center;
    }
    &:hover{
      background: #59ba57;
    }
  }
}

/* -->Block2<-- */
.block2{
  padding:44px 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1312px;
  margin: 0 auto;
  background: #F8F9FA;
  border-radius: 8px;
  @media only screen and (max-width: 1400px) {
    padding:44px 40px;
    margin: 0 32px;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    padding: 34px 22px;
  }
  h2{
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    color: #2F3137;
    max-width: 736px;
    @media only screen and (max-width: 1400px) {
      max-width: 400px;
      font-size: 32px;
      line-height: 39px;
    }
    @media only screen and (max-width: 800px) {
      font-size: 24px;
      line-height: 29px;
      padding-bottom: 62px;

    }                       
  }
  p{
    font-size: 20px;
    line-height: 24px;
    color: rgba(47, 49, 55, 0.5);
  }
  .block2Btn{
    @media only screen and (max-width: 899px) {
      max-width: 400px;
    }
    a{
    padding: 24px 120px;
    background: #69CF67;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    &:hover{
      background: #59ba57;
    }
    @media only screen and (max-width: 899px) {
      padding: 24px 60px;
    }
    @media only screen and (max-width: 899px) {
      padding: 24px auto;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  }
}

/* --> BlockForm <-- */
.blockForm{
  background: #2f3137;
  padding: 84px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  max-width: 1238px;
  margin: 0 auto 310px;
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    padding: 65px 77px;
    align-items: initial;
  }
  @media only screen and (max-width: 1250px) {
    border-radius: 0;
    margin: 0;
  }
  @media only screen and (max-width: 800px) {
    padding: 59px 24px;
  }
  h2 {
    font-weight: bold;
    font-size: 64px;
    line-height: 100%;
    color: #ffffff;
    max-width: 448px;
    padding-bottom: 35px;
    @media only screen and (max-width: 1400px) {
      max-width: 1080px;
      font-size: 48px;
      line-height: 100%;
      padding-bottom: 28px;
    }
  }
  p {
    font-size: 32px;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 1400px) {
      font-size: 20px;
      line-height: 100%;
      padding-bottom: 40px;
    }


  }
  justify-content: space-around;
  form {
    max-width: 539px;
    @media only screen and (max-width: 1400px) {
      max-width: 1080px;
    }
    input {
      background: transparent;
      color: #ffffff;
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      width: 530px;
      &::placeholder {
        color: #ffffff;
      }
      @media only screen and (max-width: 1400px) {
        width: 100%;
      }
    }
    textarea {
      background: transparent;
      color: #ffffff;
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 8px;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      width: 530px;
      resize: none;
      &::placeholder {
        color: #ffffff;
      }
      @media only screen and (max-width: 1400px) {
        width: 100%;
      }
    }
    button {
      background: #69cf67;
      &:hover {
        background: #51ba4f;
      }
    }
  }
}

/* -->Modal<-- */
.ReactModal__Overlay{
  @media only screen and (max-width: 500px) {
    max-width: 100% !important;
    
    }
  .ReactModal__Content, .ReactModal__Content--after-open{
    max-width: 853px;
    height: 949px;
    padding: 0 !important;
    margin: 0 auto;
    margin-top: calc(50vh - 510px);
    border-radius: 53px 0 0 53px !important;
    @media only screen and (max-width: 800px) {
      margin: 0;
      width: 100%;
      height: 100vh;
      left: 0 !important;
      top: 0 !important;
      border-radius: 0 !important;
    }

  @media only screen and (max-width: 500px) {
      width: 100% !important;
      right: 0 !important;
      left: 0 !important;
    }
  }
}
.ReactModal__Overlay--after-open {
  z-index: 5;
}

/* -->Table<-- */

.accordion {
	width: 100%;
}
.panel {
	background-color: #E5E5E5;
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
	&:not(:last-child) {
		margin-bottom: 3px;
	}
}
.panel__label {
	position: relative;
	display: block;
	width: 100%;
	background: none;
	border: none;
	text-align: left;
	padding: 38px 0 40px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
	-webkit-transition: color 0.2s linear;
	transition: color 0.2s linear;
	cursor: pointer;
  text-transform: uppercase;
  color: #84b583;
  @media only screen and (max-width: 800px) {
    font-size: 16px;
    line-height: 19px;
    padding-right: 37px;
  }

	&:focus {
		outline: none;
	}
	&:after {
		content: '';
		position: absolute;
		right: 25px;
		top: 40%;
		width: 14px;
		height: 2px;
		margin-top: 10px;
		background-color: #222222;
	}
	&:before {
		content: '';
		position: absolute;
		right: 25px;
		top: 40%;
		width: 14px;
		height: 2px;
		margin-top: 10px;
		background-color: #222222;
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg);
		-webkit-transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
		transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
		transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
		transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1), -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
	}
}
.panel[aria-expanded='true'] {
	.panel__label {
		color: #69CF67;
		&:before {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
	}
}
.panel__inner {
	overflow: hidden;
	will-change: height;
	-webkit-transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
	transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.panel__content {
	padding: 0 0 60px;
	font-size: 20px;
  line-height: 161%;
  letter-spacing: 0.05em;
  color: #545454;
	-webkit-transition: opacity 0.3s linear 0.18s;
	transition: opacity 0.3s linear 0.18s;
}

`;

export default GlobalStyles;
