import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

import * as S from "./styles";

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 127, height: 56) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktopImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 127, height: 56) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );
  const images = [
    data.desktopImage.childImageSharp.fixed,
    {
      ...data.mobileImage.childImageSharp.fixed,
      media: `(min-width: 480px)`,
    },
  ];
  return (
    <>
      <S.FooterWrapper>
        <div className="container">
          <div className="imgContainer">
            <Img title="Núcleo Laboratório" fixed={images} />
          </div>
          <div className="footerMenu">
            <ul>
              <li className="title">Menu</li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/servicePage">Serviços</Link>
              </li>
              <li>
                <Link to="/convenios">Convênios</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/contact">Contato</Link>
              </li>
            </ul>
          </div>
          <div className="footerSocial">
            <p className="title">Estamos nas redes sociais</p>
            <div className="social">
              <div>
                <a href="https://www.instagram.com/nucleolaboratorioceresgo/">
                  <S.GrayInstagram />
                </a>
              </div>
              <div>
                <a href="https://www.facebook.com/nucleolaboratorioceres/">
                  <S.GrayFacebook />
                </a>
              </div>
            </div>
          </div>
          <div>
            <p className="title">Endereço</p>
            <p>
              Rua Meinha Mendes, Quadra 3 Nº 90 - lote 13 Ceres – GO | CEP
              76300-000
            </p>
            <p>
            Horário de Atendimento: <br/>6:30 às 17h de Segunda à Sexta e Sábado das 7h às 11h
            </p>
          </div>
        </div>
      </S.FooterWrapper>
      <S.FooterFourUp>
        <div className="container">
          <div>
            <p>
              © Copyright 2020 - Todos os direitos reservados Núcleo Laboratório
            </p>
          </div>
          <div className="fourUp">
            <a href="https://www.fourup.com.br">
              <div className="img" />
            </a>
          </div>
        </div>
      </S.FooterFourUp>
    </>
  );
};

export default Footer;
