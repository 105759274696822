import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";
import * as S from "./styles";
import FeatherIcon from "feather-icons-react";
import whatsappIcon from '../../img/whatsapp.png';

const Navbar = ({bg}) => {
  const data = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 127, height: 56) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktopImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 120, height: 53) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const images = [
    data.desktopImage.childImageSharp.fixed,
    {
      ...data.mobileImage.childImageSharp.fixed,
      media: `(min-width: 480px)`,
    },
  ];

  const [menuOpen, setMenuOpen] = useState(false);

  function handleOpenMenu() {
    setMenuOpen(true);
    if(document.body){
      document.body.style.overflow = "hidden";
    }
  }
  function handleCloseMenu() {
    setMenuOpen(false);
    if(document.body){
      document.body.style.overflow = "auto";
    }
  }
  
  return (
    <S.NavBar role="navigation" aria-label="main-navigation">
      <div className="painel">
        <div className="container">
          <div>
            <p>Desde 1996 ao seu lado</p>
          </div>
          <div className="painelContact">
            <a href="tel:+55 62 3307-1780">
              <p className="number">(62) 3307-1780</p>
            </a>
            <a href="https://api.whatsapp.com/send?phone=5562984274865">
              <img src={whatsappIcon}/>
              <p className="number">(62) 98427-4865</p>
            </a>
            <a href="mailto:atendimento@nucleolaboratorio.com">
              <p>atendimento@nucleolaboratorio.com</p>
            </a>
          </div>
        </div>
        <div className="container">
          <form action="https://nucleolaboratorio.com/validacao.php" method="post" name="formpesquisalaudo">
            <div className="radioType">
              <p>Tipo:</p>
              <label className="radio">
                <input
                  type="radio"
                  id="clientebar"
                  name="radiotipolaudo"
                  value="cliente"
                  className="hidden"
                  defaultChecked
                />
                <span className="label"></span>Cliente
              </label>
              <label className="radio">
                <input
                  type="radio"
                  id="hospitalbar"
                  name="radiotipolaudo"
                  value="hospital"
                  className="hidden"
                />
                <span className="label"></span>Hospital
              </label>
            </div>
            <div className="inputBlock">
              <input type="text" className="inputText" name="codigoprotocolo" placeholder="Código" />
              <input type="password" className="inputText" name="senhaprotocolo" placeholder="Senha" />
            </div>
            <button className="btn">Ver laudos</button>
          </form>
        </div>
      </div>
        <div className={`menu ${!bg ? "" : "menuBgCOlor"}`}>  
          <div className="container">
            <div>
              <Link to="/" className="navbar-item" title="Núcleo Laboratório">
                <Img title="Núcleo Laboratório" fixed={images} />
              </Link>
            </div>
            <div className="navMenu">
              <div>
                <Link key="menu-home" className="navbarItem" to="/">
                  Home
                </Link>
              </div>
              <div>
                <Link key="menu-about" className="navbarItem" to="/servicePage">
                  Serviços
                </Link>
              </div>
              <div>
                <Link
                  key="menu-products"
                  className="navbarItem"
                  to="/convenios"
                >
                  Convênios
                </Link>
              </div>
              <div>
                <Link key="menu-blog" className="navbarItem" to="/blog">
                  Blog
                </Link>
              </div>
              <div>
                <Link key="menu-blog" className="navbarItem" to="/contact">
                  Contato
                </Link>
              </div>
            </div>
            <div className="respMenuBtn">
              <a onClick={() => handleOpenMenu()}>
                <FeatherIcon icon="menu" />
              </a>
            </div>
          </div>
        </div>
      {menuOpen === true ? (
        <S.MenuResponsive>
          <a onClick={() => handleCloseMenu()}>
            <div className="headerMenuResp">
              <img src="../../img/logo.png" alt="" />
              <FeatherIcon icon="x" />
            </div>
            <div>
              <div className="menuRespItem">
                <Link key="menu-home" className="navbarItem" to="/">
                  Home
                </Link>
              </div>
              <div className="menuRespItem">
                <Link key="menu-about" className="navbarItem" to="/servicePage">
                  Serviços
                </Link>
              </div>
              <div className="menuRespItem">
                <Link
                  key="menu-products"
                  className="navbarItem"
                  to="/convenios"
                >
                  Convênios
                </Link>
              </div>
              <div className="menuRespItem">
                <Link key="menu-blog" className="navbarItem" to="/blog">
                  Blog
                </Link>
              </div>
              <div className="menuRespItem">
                <Link key="menu-blog" className="navbarItem" to="/contact">
                  Contato
                </Link>
              </div>

              <div className="menuRespItem">
              </div>
            </div>
          </a>
        </S.MenuResponsive>
      ) : null}
    </S.NavBar>
  );
};

export default Navbar;
